// this file needs to enable vuex store

import * as index from '~~/store/modules'

export const state = () => ({
  ...index.state()
})
export const getters = {
  ...index.getters
}
export const actions = {
  ...index.actions
}
export const mutations = {
  ...index.mutations
}
